<template>
  <div class="section2">
    <div class="text-div">
      <div id="elementToAnimate" class="text-content">用专业知识为客户带来</div>
      <div id="elementToAnimateText" class="text-content">更美好的生活体验</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section2 {
  position: relative;
  height: 3.4rem;
  background: #fff;
  .banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 8rem;
    background: url(./imgs/section2-bg-1.png) no-repeat;
    background-size: 100% 100%;
  }

  .text-div {
    margin-top: 1.5rem;
  }

  .text-content {
    text-align: center;
    font-size: 0.5rem;
    margin-top: 0.3rem;
    //font-weight: normal; /* 或者使用400 */
  }

  // .animationTitle {
  //   animation: move-text 0.8s forwards ;
  // }

  .animationDesc {
    animation: move-text 2s forwards;
  }

  @keyframes move-text {
    0% {
      transform: translateY(0.5rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
}
</style>

<script>
export default {
  created() {
    window.addEventListener("scroll", this.onScrollFunc);
  },
  mounted() {
    this.onScrollFunc();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrollFunc);
  },

  methods: {
    onScrollFunc() {
      var elementAni = document.getElementById("elementToAnimate");
      var elementAniText = document.getElementById("elementToAnimateText");

      var position = elementAni.getBoundingClientRect().top;
      if (
        position < window.innerHeight &&
        position > -elementAni.clientHeight
      ) {
        elementAni.classList.add("animationDesc");
        elementAniText.classList.add("animationDesc");
        //console.log("1111111111111");
      } else {
        //console.log("2222222222222");
        elementAni.classList.remove("animationDesc");
        elementAniText.classList.remove("animationDesc");
      }
    }
  }
};
</script>
