<template>
  <div class="header" :class="{ 'header-absolute': absolute }">
    <div class="logobg">
      <img class="menu" src="../assets/menu.png" @click="openmenu" />
      <img class="logo" src="../assets/logo.png" />
      <img class="shop" src="../assets/shop.png" @click="openshop" />
    </div>
    <div class="popupContent" :class="{ 'popupContent-show': isShowMenuList }">
      <div
        class="content-item"
        v-for="link in links"
        :key="link.path"
        @click="go(link.path)"
        :class="{ 'content-item-checked': $route.path == link.path }"
      >
        {{ link.name }}
      </div>
    </div>

    <div class="nav">
      <div
        class="link"
        :class="{ 'link-active': $route.path == link.path }"
        v-for="link in links"
        :key="link.path"
        @click="go(link.path)"
      >
        {{ link.name }}
        <div v-if="link.children" class="submenu-box">
          <div
            class="submenu-item"
            v-for="(sub, ind) in link.children"
            :key="'sub-' + ind"
            @click.stop="go(sub.path)"
          >
            {{ sub.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["absolute"],
  data() {
    return {
      isShowMenuList: false,
      links: [
        {
          path: "/",
          name: "首页"
        },
        {
          path: "/solution",
          name: "品牌介绍"
        },
        {
          path: "/product",
          name: "品牌概述"
        },
        { path: "/case", name: "产品展示" },
        { path: "/about", name: "联系我们" }
      ]
    };
  },
  methods: {
    go(path) {
      this.$router.push(path);
    },
    openshop() {
      window.open("https://u2xihu.tmall.com");
    },
    openmenu() {
      this.isShowMenuList = !this.isShowMenuList;
    }
  }
};
</script>

<style lang="scss">
.header {
  position: relative;
  z-index: 19881111;
  width: 100%;
  height: 2rem;
  color: #fff;
  //align-content: center;
  //justify-content: center;
  justify-items: center;
  display: grid;

  .logobg {
    // width: 100%;
    // height: 2rem;
    // background: #f6f6f6;
    // display: grid;
    // align-content: center;
    // justify-content: center;
    // align-items: center;
    // justify-items: center;
    // position: absolute;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2rem;

    .logo {
      height: 1.2rem;
      width: 1.2rem;
    }

    .shop {
      height: 0.5rem;
      width: 0.5rem;
      margin-right: 1rem;
    }

    .menu {
      height: 0.5rem;
      width: 0.5rem;
      margin-left: 1rem;
    }
  }

  .nav {
    float: left;
    width: 100%;
    height: 1rem;
    line-height: 1rem;
    position: absolute;
    background: #fff;
    top: 2rem;
    display: none;
    .link {
      background: #fff;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 25%;
      height: 100%;
      line-height: 1rem;
      color: #000;
      text-decoration: none;
      transition: color 0.3s;
      font-size: 0.3rem;
      outline: none;
      text-align: center;
      cursor: pointer;
      &:hover {
        color: #dfb25f;
        .submenu-box {
          transform: scale(1, 1);
        }
      }
    }
    .link-active {
      color: #dfb25f;
      border-bottom: 1px solid #dfb25f;
    }
  }
}
.header-absolute {
  position: absolute;
  z-index: 19881111;
  top: 0;
  left: 0;
  right: 0;
}
.submenu-box {
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  width: auto;
  background: url("./home/imgs/header-menu-list-bg.png") no-repeat;
  background-size: 100% 100%;
  transition: all 0.3s;
  // transform: scale(1, 0);
  transform-origin: left top;
  .submenu-item {
    height: 0.24rem;
    line-height: 0.24rem;
    padding: 0 0.1rem;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: #fff;
    }
  }
}

.popupContent {
  position: absolute;
  top: 1.5rem;
  left: 1rem;
  width: auto;
  background: url("./home/imgs/header-menu-list-bg.png") no-repeat;
  background-size: 100% 100%;
  transition: all 0.3s;
  transform: scale(1, 0);
  transform-origin: left top;
  .content-item {
    padding: 5px 0;
    margin: 0 15px;
    height: 30px;
    width: 80px;
    line-height: 30px;
    text-align: left;
    color: #000;
    padding-left: 5px;
    border-bottom: 1px solid #e6b763;
  }
  .content-item:first-child {
    margin-top: 15px;
  }
  .content-item:last-child {
    margin-bottom: 10px;
    border-bottom: 0px solid;
  }
  .content-item-checked {
    color: #e6b763;
  }
}

.popupContent-show {
  transform: scale(1, 1);
}
</style>
