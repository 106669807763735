<template>
  <div class="section4">
    <div class="title">
      <div class="title1">
        <span class="sub">LATEST NEWS</span>
        <span>最新资讯</span>
      </div>
      <div class="title2">
        <span>快来看看U2</span>
        <span>最近又发生了什么吧！</span>
      </div>
    </div>

    <mt-swipe id="yuedu" :auto="0" class="item">
      <mt-swipe-item v-for="(item, index) in prdList" :key="index">
        <div class="card">
          <span class="date">{{ item.date }}</span>
          <span class="content">
            {{ item.name }}
          </span>
          <span class="more" @click="gotoDetail(index)">阅读更多</span>
          <img class="bgimg" :src="item.img" />
        </div>
      </mt-swipe-item>
    </mt-swipe>

    <!-- <transition name="fades4">
      <div class="news" v-if="currentIndex == 2">
        <div class="card">
          <span class="date">05/12-2024</span>
          <span class="content">
            因此,基于距离格勒诺布尔和奥运会的5分钟路程,这是当时S.1.C.0.的一个机会,能更多在气溶胶产品领域被认知.因此, U2品牌销售广告语"喷雾中呼吸阿尔卑斯山清新的空气."这在当时更像是一个沟通行动,而不是一个真正的产品宣传...
          </span>
          <span class="more" @click="gotoDetail(2)">阅读更多</span>
          <img class="bgimg" src="./imgs/section4-bg.png"  />
        </div>
      </div>
    </transition> -->

    <!-- <div class = "outmost">
      <div :class="currentIndex == 0 ? 'imgdiv imgdivsel' : 'imgdiv'"></div>
      <div :class="currentIndex == 1 ? 'imgdiv imgdivsel' : 'imgdiv'"></div>
      <div :class="currentIndex == 2 ? 'imgdiv imgdivsel' : 'imgdiv'"></div>
    </div> -->

    <div class="videonews">
      <!-- <video class="video_div" controls x5-video-player-type="h5" src="http://m.u2live.cn/video_01.mp4" type="video/mp4">
      </video> -->
      <video
          ref="videoPlayer"
          class="video-js vjs-default-skin video_div"
          controls
          preload="auto"
          poster="./imgs/video_bg.jpg"
          data-setup="{}"
        >
        <source src="http://m.u2live.cn/video_01.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';


export default {
  name: 'VideoPlayer',
  data() {
    return {
      currentIndex: 0,
      prdList: [
        {
          date: "03/12-2024",
          name:
            "近日，全球知名的清洁品牌U2宣布正式进入中国市场。在全球经济面临挑战和不确定性的背景下，这一举动展现了U2对中国市场的坚定信心。进军中国市场的决策不仅是U2品牌在全球战略中的关键一步，也是对...",
          img: require("./imgs/section4-bg.png")
        },
        {
          date: "04/12-2024",
          name:
            "研发历史跨过3/4个世纪，在产品面世的第40个年头，U2品牌正式宣布进入中国民用清洁领域。高端电器产品的清洁一直是个难题，内部结构精密复杂，给日常清洁带来不小的困难。而U2的高端清洁洗护将提交...",
          img: require("./imgs/section5-bg-2.png")
        }
      ]
    };
  },
  watch: {
    currentIndex() {
      if (this._ind) {
        clearTimeout(this._ind);
        this._ind = setTimeout(this.next, 5000);
      }
    }
  },
  methods: {
    next() {
      this.currentIndex++;
      if (this.currentIndex >= 2) {
        this.currentIndex = 0;
      }
    },
    prev() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = 1;
      }
    },
    gotoDetail(num) {
      //console.log("num  " + num);
      this.$router.push({ name: "details", params: { id: num } });
    },
  },
  created() {
    this._ind = setTimeout(this.next, 3000);
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
      console.log('Player is ready');
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }

    if (this._ind) {
      clearTimeout(this._ind);
    }
  }
};
</script>

<style lang="scss" scoped>
.section4 {
  height: 20rem;
  position: relative;
  background: #f9f9fd;
  padding: 0 0.5rem;
  padding-top: 0.3rem;
  .title {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    .title1 {
      font-size: 0.5rem;
      display: grid;
      align-content: center;
      justify-content: left;
      align-items: center;
      justify-items: center;
      width: 3.3rem;
      border-right: 1px solid #212121;
      .sub {
        font-size: 0.28rem;
      }
    }
    .title2 {
      font-size: 0.3rem;
      display: grid;
      align-content: center;
      justify-content: center;
      align-items: center;
      justify-items: start;
      width: 4.3rem;
      line-height: 0.48rem;
    }
  }

  .card {
    background: #fff;
    width: 100%;
    position: relative;

    .date {
      font-size: 0.64rem;
      margin-left: 0.2rem;
    }

    .content {
      font-size: 0.32rem;
      width: 92%;
      margin: 0.2rem;
      line-height: 0.8rem;
    }

    .more {
      font-size: 0.28rem;
      color: #0087ff;
      margin-left: 0.2rem;
    }

    .bgimg {
      width: 100%;
      margin-top: 0.4rem;
      margin-bottom: -0.1rem;
      height: 4rem;
    }
  }

  .news {
    position: absolute;
    width: 90%;
    // background: url("./imgs/section4-bg.png") no-repeat;
    // background-size: 100% 100%;
    height: 3rem;
    // margin: 0.2rem 0.3rem;

    .card {
      background: #fff;
      height: 5.8rem;
      width: 100%;
      position: relative;
      // left: 0.6rem;
      top: 0.6rem;

      .date {
        font-size: 0.6rem;
        margin-left: 0.2rem;
      }

      .content {
        font-size: 0.3rem;
        width: 75%;
        margin: 0.2rem;
        // line-height: 0.2rem;
      }

      .more {
        font-size: 0.25rem;
        color: #0087ff;
      }
    }
  }

  .news2 {
    position: absolute;
    width: 90%;
    // background: url("./imgs/section4-bg.png") no-repeat;
    // background-size: 100% 100%;
    height: 3rem;
    margin: 0.2rem 0.3rem;

    .card {
      background: #fff;
      height: 2.4rem;
      width: 100%;
      position: relative;
      left: 0.6rem;
      top: 0.6rem;

      .date {
        font-size: 0.6rem;
        margin-left: 0.2rem;
      }

      .content {
        font-size: 0.3rem;
        width: 75%;
        margin: 0.2rem;
        // line-height: 0.2rem;
      }

      .more {
        font-size: 0.06rem;
        color: #0087ff;
      }
    }
  }

  .news3 {
    position: absolute;
    width: 90%;
    // background: url("./imgs/section4-bg.png") no-repeat;
    // background-size: 100% 100%;
    height: 3rem;
    margin: 0.2rem 0.3rem;

    .card {
      background: #fff;
      height: 2.4rem;
      width: 100%;
      position: relative;
      left: 0.6rem;
      top: 0.6rem;

      .date {
        font-size: 0.6rem;
        margin-left: 0.2rem;
      }

      .content {
        font-size: 0.3rem;
        width: 75%;
        margin: 0.2rem;
        // line-height: 0.2rem;
      }

      .more {
        font-size: 0.06rem;
        color: #0087ff;
      }
    }
  }

  .fades4-enter-active,
  .fades4-leave-active {
    transition: opacity 0.5s;
  }
  .fades4-enter,
  .fades4-leave-to {
    opacity: 0;
  }

  .videonews {
    //background: url("./imgs/section5-bg.png") no-repeat;
    //background-size: 100% 100%;
    height: 4.5rem;
    width: 100%;
    margin-top: -9rem;

    .video_div {
      width: 100%;
      height: 100%;
    }
  }

  .outmost {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 13.7rem;
    // left: 40%;
    width: 90%;
  }

  .imgdiv {
    height: 0.04rem;
    width: 0.5rem;
    background-color: #0087ff;
    opacity: 0.2;
    margin: 0 0.03rem; /* 可选，用于子div之间的间隔 */
  }

  .imgdivsel {
    background-color: #0087ff;
    opacity: 1;
  }
}
</style>

<style lang="scss">
#yuedu {
  .mint-swipe-indicators {
    bottom: 9.5rem !important;
    // .mint-swipe-indicator {
    //   background: #fff;
    //   opacity: 0.8;
    // }
    .is-active {
      background: #333 !important;
      opacity: 1;
    }
  }
}
</style>
