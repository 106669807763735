<template>
  <div class="section3">
    <mt-swipe
      :auto="0"
      :show-indicators="false"
      class="swipper"
      @change="handleBannerChange"
    >
      <mt-swipe-item v-for="(item, index) in bannerList" :key="index">
        <div class="swipper-item">
          <img class="swipper-item-img" :src="item.img" />
          <!-- <div class="swipper-item-text">
            {{item.name}}
          </div> -->
        </div>
      </mt-swipe-item>
    </mt-swipe>
    <div class="swipper-item-text">
      {{ bannerList[currentIndex].name }}
    </div>
    <div class="bannerIndicators">
      <div
        class="bannerIndicatorItem"
        v-for="(item, index) in bannerList"
        :key="index"
        :class="{ 'bannerIndicatorItem-Sel': index == currentIndex }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      bannerList: [
        {
          id: 0,
          name:
            "TDS是一项用来评估水质的重要指标之一，TDS值越高，就表示水中含有的杂质越多，水中的多种杂质会产生不同类型的水垢。",
          img: require("./imgs/section3-01.png")
        },
        {
          id: 0,
          name:
            "TDS是一项用来评估水质的重要指标之一，TDS值越高，就表示水中含有的杂质越多，水中的多种杂质会产生不同类型的水垢。",
          img: require("./imgs/section3-02.png")
        },
        {
          id: 0,
          name:
            "TDS是一项用来评估水质的重要指标之一，TDS值越高，就表示水中含有的杂质越多，水中的多种杂质会产生不同类型的水垢。",
          img: require("./imgs/section3-03.png")
        }
      ]
    };
  },
  watch: {
    currentIndex() {
      if (this._ind) {
        clearTimeout(this._ind);
        this._ind = setTimeout(this.next, 5000);
      }
    }
  },
  methods: {
    next() {
      this.currentIndex++;
      if (this.currentIndex > 2) {
        this.currentIndex = 0;
      }
    },
    prev() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = 2;
      }
    },
    handleBannerChange(index) {
      this.currentIndex = index;
    }
  },
  created() {
    // this._ind = setTimeout(this.next, 5000);
  },
  beforeDestroy() {
    if (this._ind) {
      clearTimeout(this._ind);
    }
  }
};
</script>

<style lang="scss" scoped>
.section3 {
  position: relative;
  height: 10rem;
  .swipper {
    position: relative;
    height: 6.8rem;
    width: 100%;
    .swipper-item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      // height: 5.4rem;
    }

    .swipper-item-text {
      background: #c1e1fb;
      // height: 0.8rem;
      font-size: 0.3rem;
      padding: 0.6rem 1rem;
    }

    .swipper-item-img {
      width: 100%;
    }

    .swipper-item1 {
      background: url(./imgs/section3-01.png) no-repeat;
      background-size: 100% 100%;
    }
    .swipper-item2 {
      background: url(./imgs/section3-02.png) no-repeat;
      background-size: 100% 100%;
    }
    .swipper-item3 {
      background: url(./imgs/section3-03.png) no-repeat;
      background-size: 100% 100%;
    }
    .title {
      font-size: 0.34rem;
      text-align: center;
      margin-top: 1rem;
    }
    .items {
      font-size: 0;
      text-align: center;
      margin-top: 0.3rem;
      .item {
        display: inline-block;
        font-size: 0.15rem;
        line-height: 0.15rem;
        vertical-align: middle;
        margin-right: 0.1rem;
        padding-right: 0.1rem;
        border-right: 1px solid #fff;
        &:last-child {
          margin-right: 0;
          border: none;
        }
      }
    }
  }

  .swipper-item-text {
    background: #c1e1fb;
    // height: 0.8rem;
    font-size: 0.3rem;
    padding: 0.6rem 1rem;
  }

  .bannerIndicators {
    position: absolute;
    bottom: 3.5rem;
    left: 48.5%;
    transform: translateX(-50%);
    display: flex;
    .bannerIndicatorItem {
      height: 0.16rem;
      width: 0.4rem;
      background-color: #ffffff;
      border-radius: 5px;
      opacity: 0.5;
      cursor: pointer;
      margin-left: 10px;
    }

    .bannerIndicatorItem-Sel {
      background-color: #212121;
      opacity: 1;
    }
  }
}
</style>
