<template>
  <div class="section4">
    <div class="text-div-s4">
      <div id="elementToAnimate-s4" class="text-content-s4">
        不断创新、积极进取，
      </div>
      <div id="elementToAnimateText-s4" class="text-content-s4">
        为每个家庭带来更优质的产品
      </div>
      <div id="elementToAnimateContent-s4" class="text-content-s4">
        和更温暖的服务
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.section4 {
  position: relative;
  height: 4.6rem;
  background: #fff;
  .banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4.6rem;
    background: url(./imgs/section4-bg-1.png) no-repeat;
    background-size: 100% 100%;
  }

  .text-div-s4 {
    margin-top: 1.5rem;
  }

  .text-content-s4 {
    text-align: center;
    font-size: 0.5rem;
    margin-top: 0.3rem;
    //font-weight: normal; /* 或者使用400 */
  }

  // .animationTitle-s4 {
  //   animation: move-text 0.8s forwards ;
  // }

  .animationDesc-s4 {
    animation: move-text-s4 2s forwards;
  }

  @keyframes move-text-s4 {
    0% {
      transform: translateY(0.5rem);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
}
</style>

<script>
export default {
  created() {
    window.addEventListener("scroll", this.onScrollFunc);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScrollFunc);
  },
  methods: {
    onScrollFunc() {
      var elementAni = document.getElementById("elementToAnimate-s4");
      var elementAniText = document.getElementById("elementToAnimateText-s4");
      var elementAniContent = document.getElementById(
        "elementToAnimateContent-s4"
      );

      var position = elementAni.getBoundingClientRect().top;
      if (
        position < window.innerHeight &&
        position > -elementAni.clientHeight
      ) {
        elementAni.classList.add("animationDesc-s4");
        elementAniText.classList.add("animationDesc-s4");
        elementAniContent.classList.add("animationDesc-s4");
        //console.log("1111111111111");
      } else {
        //console.log("2222222222222");
        elementAni.classList.remove("animationDesc-s4");
        elementAniText.classList.remove("animationDesc-s4");
        elementAniContent.classList.remove("animationDesc-s4");
      }
    }
  }
};
</script>
