<template>
  <div class="section4">
    <mt-swipe
      id="yuedu"
      :auto="3000"
      :show-indicators="true"
      class="swiview"
    >
      <mt-swipe-item v-for="(item, index) in prdlist" :key="index">
        <img class="swipper-item " :src="item.img" />
      </mt-swipe-item>
    </mt-swipe>

    <!-- <div class="imgdiv">
      <img class="imgdutu" src="./imgs/ditu.png" />
    </div> -->
    <!-- <div class="cells">
      <div class="cell cell1">
        <div class="cell-title">
          布莱斯工厂
        </div>
      </div>
      <div class="cell cell2">
        <div class="cell-title">
          莫朗工厂
        </div>
      </div>
      <div class="cell cell3">
        <div class="cell-title">
          圣埃格雷夫工厂
        </div>
      </div>
      <div class="cell cell4">
        <div class="cell-title">
          沃雷普工厂
        </div>
      </div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.section4 {
  position: relative;
  height: 9rem;
  background: url("./imgs/section3-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 1px 0;

  .swiview {
    position: relative;
    // top: 2rem;
    height: 9rem;

    .swipper-item {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cells {
    // margin: 0.615rem 0.9rem;
    margin-top: 0.4rem;
    margin-left: 1.15rem;
    font-size: 0;
    .cell {
      display: inline-block;
      vertical-align: middle;
      width: 3.4rem;
      height: 1.8rem;
      margin-top: 0.5rem;
      margin-right: 0.43rem;
      border-radius: 3px;
      transition: all 0.3s;
      &:last-child {
        margin-right: 0;
      }
    }

    .cell1 {
      background: url("./imgs/section3-bg-1.png") no-repeat;
      background-size: 100% 100%;
    }

    .cell2 {
      background: url("./imgs/section3-bg-2.png") no-repeat;
      background-size: 100% 100%;
    }

    .cell3 {
      background: url("./imgs/section3-bg-3.png") no-repeat;
      background-size: 100% 100%;
    }

    .cell4 {
      background: url("./imgs/section3-bg-4.png") no-repeat;
      background-size: 100% 100%;
    }

    .cell-title {
      margin-top: 1.9rem;
      font-size: 0.3rem;
      line-height: 0.17rem;
      color: #fff;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      img {
        height: 0.1rem;
        margin-right: 0.05rem;
      }
    }
    .cell-txt {
      margin: 0.49rem 0.3rem;
      font-size: 0.11rem;
      color: #171717;
      text-align: center;
      line-height: 0.2rem;
    }
  }

  .imgdiv {
    width: 100%;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    height: 6rem;

    .imgdutu {
      width: 90%;
    }
  }

  .item {
    width: 100%;
    animation: scrollLeft 5s linear infinite;
  }

  .prdlist {
    width: 100px;
    display: flex;
  }

  .prdimg {
    width: 70%;
    margin-top: 1.5rem;
    left: 16%;
    position: absolute;
  }

  /* 定义动画 */
  @keyframes scrollLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(
        calc(-100% - 10px)
      ); /* 加上一些额外的空白，以便滚动到最后一个元素时不会立即切换 */
    }
  }
}
</style>


<style lang="scss">
#yuedu {
  .mint-swipe-indicators {
    bottom: 0.2rem !important;

    .mint-swipe-indicator {
      background: #fff;
      opacity: 0.3;
    }
    .is-active {
      background: #fff !important;
      opacity: 1;
    }
  }
}
</style>


<script>
export default {
  data() {
    return {
      prdlist: [
        {
          id: 1,
          name: "布莱斯",
          img: require("./imgs/prdimg-1.png"),
          class: "prdimg"
        },
        {
          id: 2,
          name: "莫朗",
          img: require("./imgs/prdimg-2.png"),
          class: "prdimg"
        },
        {
          id: 3,
          name: "圣埃格雷夫",
          img: require("./imgs/prdimg-3.png"),
          class: "prdimg"
        },
        {
          id: 4,
          name: "沃雷普",
          img: require("./imgs/prdimg-4.png"),
          class: "prdimg"
        }
      ],
      showprdlist: []
    };
  },
  created() {
    for (let i = 0; i < 1000; i++) {
      this.showprdlist = this.showprdlist.concat(this.prdlist);
    }
  },
  methods: {}
};
</script>
