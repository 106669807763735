<template>
  <div class="section1">
    <img class="bg" src="./imgs/section1.png" />
    <span class="title">受益于丰富的经验，</span>
    <span class="title2">
      几十年来，
      <img class="icon" src="../../assets/logo.png" />
      一直以高专业性和美妙的香气驰名于清洁卫生领域
    </span>
    <img src="./imgs/section1-item.png" class="prd" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  height: 4.8rem;
  background: #fff;
  .bg {
    height: 3.8rem;
    width: 100%;
  }

  .title {
    font-size: 0.3rem;
    color: #212121;
    position: absolute;
    left: 1rem;
    bottom: 2rem;
  }

  .title2 {
    font-size: 0.3rem;
    color: #212121;
    position: absolute;
    left: 1rem;
    bottom: 1.1rem;
    width: 55%;
  }

  .icon {
    width: 0.3rem;
  }

  .prd {
    width: 3.5rem;
    position: absolute;
    left: 6rem;
    bottom: 0rem;
  }
}
</style>
