<template>
  <div class="section4">
    <div class="title3">
      权威检测
    </div>
    <div class="title2">
      值得信赖
    </div>
    <div class="title1">
      <p>/</p>
      <span>
        数据来自有资质的第三方检测机构，在实验条件下，对H1N1流感病毒、Hcov-229E冠状病毒灭活，符合消毒合格规定。(参照《消毒技术规范2002年版》)。
      </span>
      <span style="top: 2.5rem;">对大肠杆菌、金黄色葡萄球菌、铜绿假单胞菌、白色念珠菌均具有杀灭作用。</span>
      <span style="top: 3.6rem;">*采用国标检验方法，数据在实验条件下取得。</span>
      <br /><br /><br />
      <p>/</p>
      <span>为什么不同水质不同配方？</span>
      <br />
      <span>
        不同的水质会导致洗衣机中不同程度矿物质沉积和污垢堆积，使用特定的清洁配方有助于更针对性地解决这些差异，保证内筒的清洁效果。据水质调整清洗剂配方有助于溶解特定残留物。
      </span>
    </div>
    <img src="./imgs/section4-bg-1.png" class="year" />
    <img src="./imgs/section4-goshop.png" class="goshop" @click="openshop" />
  </div>
</template>

<script>
export default {
  methods: {
    openshop() {
      window.open("https://u2xihu.tmall.com");
    }
  }
};
</script>

<style lang="scss" scoped>
.section4 {
  position: relative;
  height: 14rem;
  background: #fff;
  .title2 {
    position: absolute;
    top: 1.5rem;
    left: 1.23rem;
    font-size: 0.6rem;
    line-height: 0.34rem;
    color: #242424;
  }
  .title3 {
    position: absolute;
    top: 0.775rem;
    left: 1.23rem;
    font-size: 0.6rem;
    line-height: 0.34rem;
    color: #242424;
  }
  .title1 {
    position: absolute;
    top: 3rem;
    left: 1.23rem;
    font-size: 0.6rem;
    line-height: 1.2rem;
    color: #242424;
    width: 75%;
    p {
      color: #000;
      font-weight: bold;
      font-size: 0.3rem;
      line-height: 0.3rem;
      width: 3rem;
    }

    span {
      color: #242424;
      font-size: 0.3rem;
      line-height: 0.5rem;
      width: 100%;
      position: absolute;
    }
  }
  .year {
    position: absolute;
    top: 0.775rem;
    left: 4.5rem;
    height: 2.5rem;
  }
  .goshop {
    position: absolute;
    top: 12rem;
    left: 1.2rem;
    height: 2rem;
  }
  .article1 {
    position: absolute;
    top: 2.32rem;
    left: 0.95rem;
    right: 0.95rem;
  }
  .article2 {
    position: absolute;
    top: 3.685rem;
    left: 0.95rem;
    right: 0.95rem;
  }
  .title {
    font-size: 0.18rem;
    color: #000;
    margin-bottom: 0.3rem;
  }
  .txt {
    font-size: 0.13rem;
    color: #000;
    line-height: 0.3rem;
  }
}
</style>
