<template>
  <div class="slogan">
    <Header :absolute="true"></Header>
    <!-- <div class="arrows">
      <img
        class="arrow-item arrow-item-prev"
        src="./imgs/arrow.png"
        @click="prev"
      />
      <img class="arrow-item" src="./imgs/arrow.png" @click="next" />
    </div> -->
    <!-- <div class="swipper">
      <transition name="fade">
        <div class="swipper-item swipper-item1" v-if="currentIndex == 0">
          
        </div>
      </transition>
      <transition name="fade">
        <div class="swipper-item swipper-item2" v-if="currentIndex == 1">
          
        </div>
      </transition>
      <transition name="fade">
        <div class="swipper-item swipper-item3" v-if="currentIndex == 2">
          
        </div>
      </transition>
    </div> -->

    <mt-swipe
      :auto="0"
      :show-indicators="false"
      class="swiview"
      @change="handleBannerChange"
    >
      <mt-swipe-item v-for="(item, index) in swiList" :key="index">
        <img class="swipper-item " :src="item.img" />
      </mt-swipe-item>
    </mt-swipe>
    <div class="bannerIndicators">
      <div
        class="bannerIndicatorItem"
        v-for="(item, index) in swiList"
        :key="index"
        :class="{ 'bannerIndicatorItem-Sel': index == currentIndex }"
      ></div>
    </div>
  </div>
</template>

<script>
import Header from "../Header";

export default {
  components: {
    Header
  },
  data() {
    return {
      currentIndex: 0,
      swiList: [
        { img: require("./imgs/section1-bg1.png") },
        { img: require("./imgs/section1-bg2.png") },
        { img: require("./imgs/section1-bg3.png") }
      ]
    };
  },
  watch: {
    // currentIndex() {
    //   if (this._ind) {
    //     clearTimeout(this._ind);
    //     this._ind = setTimeout(this.next, 5000);
    //   }
    // }
  },
  methods: {
    next() {
      this.currentIndex++;
      if (this.currentIndex > 2) {
        this.currentIndex = 0;
      }
    },
    prev() {
      this.currentIndex--;
      if (this.currentIndex < 0) {
        this.currentIndex = 2;
      }
    },
    handleBannerChange(index) {
      this.currentIndex = index;
    }
  },
  created() {
    //this._ind = setTimeout(this.next, 5000);
  },
  beforeDestroy() {
    // if (this._ind) {
    //   clearTimeout(this._ind);
    // }
  }
};
</script>

<style lang="scss" scoped>
.slogan {
  position: relative;
  height: 12.6rem;
  .arrows {
    position: absolute;
    z-index: 2;
    left: 1.37rem;
    top: 10.94rem;
    .arrow-item {
      display: inline-block;
      vertical-align: middle;
      width: 0.5rem;
      margin-right: 0.1rem;
      opacity: 0.5;
      transition: all 0.2s;
      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
    .arrow-item-prev {
      transform: rotate(180deg);
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .swipper {
    position: relative;
    height: 7.6rem;
    .swipper-item {
      position: absolute;
      top: 2rem;
      left: 0;
      right: 0;
      height: 10rem;
    }
    .swipper-item1 {
      background: url(./imgs/section1-bg1.png) no-repeat;
      background-size: 100% 100%;
    }
    .swipper-item2 {
      background: url(./imgs/section1-bg2.png) no-repeat;
      background-size: 100% 100%;
    }
    .swipper-item3 {
      background: url(./imgs/section1-bg3.png) no-repeat;
      background-size: 100% 100%;
    }
    .title {
      font-size: 0.34rem;
      text-align: center;
      margin-top: 1rem;
    }
    .items {
      font-size: 0;
      text-align: center;
      margin-top: 0.3rem;
      .item {
        display: inline-block;
        font-size: 0.15rem;
        line-height: 0.15rem;
        vertical-align: middle;
        margin-right: 0.1rem;
        padding-right: 0.1rem;
        border-right: 1px solid #fff;
        &:last-child {
          margin-right: 0;
          border: none;
        }
      }
    }
  }

  .swiview {
    position: relative;
    top: 2rem;
    height: 10rem;

    .swipper-item {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .bannerIndicators {
    position: absolute;
    bottom: 0.2rem;
    left: 48.5%;
    transform: translateX(-50%);
    display: flex;
    .bannerIndicatorItem {
      height: 0.16rem;
      width: 0.4rem;
      background-color: #c6c6c6;
      border-radius: 5px;
      opacity: 0.5;
      cursor: pointer;
      margin-left: 10px;
    }

    .bannerIndicatorItem-Sel {
      background-color: #333333;
      opacity: 1;
    }
  }
}
</style>
