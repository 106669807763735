<template>
  <div class="section2">
    <div class="title">
      <span>作为欧洲家喻户晓的家清品牌</span>
      <span>U2每年为法国和国际上的700多家品牌客户</span>
      <span>提供各种类型的清洁产品</span>
    </div>
    <div class="title2">
      <span>迄今为止，U2为空客、凡尔赛宫、卢浮宫等众多名流</span>
      <span>奉献了创新、高效、瑰丽的专业之作。</span>
    </div>

    <mt-swipe id="yaoping" :auto="0" class="item">
      <mt-swipe-item v-for="(item, index) in prdList" :key="index">
        <div class="card">
          <img class="prdimg" :src="item.img" />
          <div>
            {{ item.name }}
            <!-- <img class="arrowright" src="./imgs/section2-arrow-right.png" /> -->
          </div>
        </div>
      </mt-swipe-item>
    </mt-swipe>
    <div class="product">
      <img class="img-bg" src="./imgs/section2-bg.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fadesName: "leftfades",
      newX: 0,
      oldX: 0,
      identi: 0,
      currentIndex: 0,
      prdList: [
        {
          name: "洗衣机清洁剂",
          img: require("./imgs/section2-item1.png")
        },
        {
          name: "洗衣机清洁剂",
          img: require("./imgs/section2-item2.png")
        },
        {
          name: "洗衣机清洁剂",
          img: require("./imgs/section2-item3.png")
        },
        {
          name: "洗衣机清洁剂",
          img: require("./imgs/section2-item5.png")
        },
        {
          name: "洗衣机清洁剂",
          img: require("./imgs/section2-item6.png")
        },
        {
          name: "洗衣机清洁剂",
          img: require("./imgs/section2-item7.png")
        }
        
        // {name: "洗衣机除菌消毒清洁剂", img: require("./imgs/section2-item4.png")}
      ]
    };
  },
  watch: {},
  methods: {},
  created() {},
  beforeDestroy() {}
};
</script>

<style lang="scss" scoped>
.section2 {
  position: relative;
  height: 17rem;
  .title {
    position: relative;
    font-size: 0.38rem;
    line-height: 0.8rem;
    padding-top: 0.2rem;
    padding-bottom: 0.15rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    // margin: 0 3.5rem;
    box-sizing: border-box;
    display: grid;
    align-content: center;
    justify-content: center;
    justify-items: center;
    &::after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 45%;
      width: 10%;
      border-bottom: 1px solid #b7b7b7;
    }
  }
  .title2 {
    position: relative;
    font-size: 0.3rem;
    line-height: 0.8rem;
    padding-top: 0.15rem;
    padding-bottom: 0.2rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    // margin: 0 3.5rem;
    box-sizing: border-box;
    display: grid;
    align-content: center;
    justify-content: center;
    justify-items: center;
  }

  .item {
    .card {
      background: #f7f7f7;
      //width: 41%;
      //height: 4.5rem;
      position: relative;
      font-size: 0.3rem;
      text-align: center;
      //margin-left: 6%;
      //margin-top: 0.5rem;

      .prdimg {
        width: 50%;
        margin-top: 1.2rem;
        // margin-top: 0.3rem;
      }

      div {
        //bottom: 0;
        //left: 0.3rem;
        //right: 0.1rem;
        //position: absolute;
        height: 2.6rem;
        line-height: 1rem;
        //width: 86%;
        display: grid;
        place-content: center;
        justify-items: center;
        margin-top: -0.8rem;

        .arrowright {
          width: 0.85rem;
          float: right;
          // margin-top: 0.4rem;
        }
      }
    }
  }

  .product {
    // background: url("./imgs/section2-bg.png") no-repeat;
    // background-size: 100% 100%;
    position: relative;
    height: 4.3rem;
    margin-top: -8rem;
    z-index: 12122;

    .img-bg {
      width: 100%;
    }
  }

  .leftfades-enter-active,
  .leftfades-leave-active {
    // transition: opacity 0.5s;
    transition: 1s linear;
  }
  .leftfades-enter {
    transform: translateX(100%);
  }

  .leftfades-leave-to {
    transform: translateX(-100%);
  }

  .rightfades-enter-active,
  .rightfades-leave-active {
    // transition: opacity 0.5s;
    transition: 1s linear;
  }
  .rightfades-enter {
    transform: translateX(-100%);
  }

  .rightfades-leave-to {
    transform: translateX(100%);
  }
}
</style>
<style lang="scss">
#yaoping {
  .mint-swipe-indicators {
    bottom: 8rem !important;
    // .mint-swipe-indicator {
    //   background: #fff;
    //   opacity: 0.8;
    // }
    .is-active {
      background: #310 !important;
      opacity: 1;
    }
  }
}
</style>
