<template>
  <div style="height: 100%;">
    <div class="section3">
      <img class="title" src="./imgs/section3-title.png" />
      <img class="list" src="./imgs/section3-bg.png" />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../Footer";

export default {
  components: {
    Footer
  }
};
</script>

<style lang="scss" scoped>
.section3 {
  height: 5.4rem;
  background: #f9f9fd;
  background-size: 100% 100%;
  .title {
    width: 70%;
    margin-left: 15%;
  }
  .list {
    width: 100%;
  }
}
</style>
