<template>
  <div class="section1">
    <Header></Header>
    <div class="banner"></div>
  </div>
</template>

<script>
import Header from "../Header";

export default {
  components: {
    Header
  }
};
</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  height: 13rem;
  .banner {
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    height: 10rem;
    background: url(./imgs/section1-bg.png) no-repeat;
    background-size: 100% 100%;
  }
}
</style>
